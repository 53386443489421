import React, { useState } from 'react';
import Loader from './Loader';

import { Form, Table, Button, Row, Col } from 'react-bootstrap';

import axiosInstance from '../utils/axiosInstance';

import { statusOptions } from '../constants';
import { conditionsList } from '../constants';
import ExportToCSV from './ExportToCSV';
import FreightOptions from '../components/FreightOptions';
import numeral from 'numeral';

const CCCReportSearch = () => {

  //const  { page, fromDate, toDate, selectedUsers, selectedStatus } = useParams();

  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [data, setData] = useState('');
  const [condition, setCondition] = useState([]); // Initialize condition as an empty array
  const [status, setStatus] = useState([]); // Initialize status as an empty array
  const [freightOption, setFreightOption] = useState([]);
  const [isFreeShippingOn, setIsFreeShippingOn] = useState(false);
  const [isPaidShippingOn, setIsPaidShippingOn] = useState(false);
  const [listPriceMin, setListPriceMin] = useState(0.0);
  const [listPriceMax, setListPriceMax] = useState(0.0);
  const [isPriceEnabled, setIsPriceEnabled] = useState(false);
  const [isShippingEnabled, setIsShippingEnabled] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(500);




  const handleBlurChange = (priceType) => {
    if (priceType === 'listPriceMin') {
      setListPriceMin(numeral(listPriceMin).format('0.00'));
    }
    else if (priceType === 'listPriceMax') {
      setListPriceMax(numeral(listPriceMax).format('0.00'));
    }

  };



  const fetchReportData = async (pageNumber = currentPage, exportAll = false) => {
    try {
      setLoading(true);
      const { data } = await axiosInstance.get('/api/reports/cccReport', {
        params: {
          condition,
          status,
          isPriceEnabled,
          isShippingEnabled,
          isFreeShippingOn,
          isPaidShippingOn,
          freightOption,
          listPriceMin,
          listPriceMax,
          page: pageNumber,
          limit: itemsPerPage,
          exportAll
        },
      });


      setData(data);



      // setReportToCSVData(data.productsToExport);
    } catch (err) {
      setLoading(false);
      setData(null);
      setError(err.response?.data?.message || 'An error occurred');
    } finally {
      setLoading(false);
    }
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    setCurrentPage(1); // Reset to first page on new search
    await fetchReportData(1);
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
    fetchReportData(newPage);
  };

  const headers = [{ label: 'Part Number', key: 'partNumber' }, { label: 'Unique No', key: 'uniqueNumber' },
  { label: 'List', key: 'listPrice' },
  { label: 'Net', key: 'listPrice' },
  { label: 'Location', key: 'location' },
  { label: 'Dimensions', key: 'dimensions' },
  { label: 'Shipping', key: 'shipping' },
  { label: 'Status', key: 'status' },
  { label: 'Description', key: 'description' },

  ];

  const reportHeaders = [{ label: 'Oem', key: 'partNumber' },
  { label: 'StockNumbernique No', key: 'uniqueNumber' },
  { label: 'Net', key: 'listPrice' },
  { label: 'List', key: 'listPrice' },
  { label: 'PartType', key: 'partType' },
  { label: 'Description', key: 'description' },
  { label: 'YardLocation', key: 'yardLocation' },
  { label: 'Available', key: 'available' },
  ];
  return (
    <>
      <Form onSubmit={submitHandler} className="p-3 border rounded shadow-sm">
        <Row className="g-3 align-items-end">
          {/* Condition Dropdown */}
          <Col md={3}>
            <Form.Group>
              <Form.Label>Condition</Form.Label>
              <Form.Select
                value={condition}
                onChange={(e) => setCondition(Array.from(e.target.selectedOptions, option => option.value))}
                multiple
                required
              >
                {conditionsList.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </Form.Select>

            </Form.Group>
          </Col>
          <Col md={3}>
            <Form.Group>
              <Form.Label>Status</Form.Label>
              <Form.Select
                value={status}
                onChange={(e) => setStatus(Array.from(e.target.selectedOptions, option => option.value))}
                multiple
                required
              >
                {
                  statusOptions.map((option) => (

                    (<option key={option} value={option}>
                      {option}
                    </option>
                    )))}

              </Form.Select>

            </Form.Group>
          </Col>
          {/* Freight Option Dropdown */}
          <Col md={1}>
            <Form.Group>

              <FreightOptions

                freightOption={freightOption}
                onFreightOptionChange={(value) => setFreightOption(value)}
                canEditField='true'
                multiple={true}
              />
            </Form.Group>
          </Col>
          <Form.Group controlId="enableShipping">
            <Form.Check
              type="checkbox"
              label="Activate Shipping Filter"
              checked={isShippingEnabled}
              onChange={() => setIsShippingEnabled(!isShippingEnabled)}
            />
          </Form.Group>
          {isShippingEnabled && (
            <><Col md={2}>
              <Form.Group>
                <Form.Check
                  type="switch"
                  id="freeShipping"
                  label="Free Shipping"
                  checked={isFreeShippingOn}
                  onChange={() => setIsFreeShippingOn(!isFreeShippingOn)} />
              </Form.Group>
            </Col><Col md={2}>
                <Form.Group>
                  <Form.Check
                    type="switch"
                    id="paidShipping"
                    label="Paid Shipping"
                    checked={isPaidShippingOn}
                    onChange={() => setIsPaidShippingOn(!isPaidShippingOn)} />
                </Form.Group>
              </Col></>)}
        </Row> <br></br>
        <Form.Group controlId="enablePrice">
          <Form.Check
            type="checkbox"
            label="Activate Price Filter"
            checked={isPriceEnabled}
            onChange={() => setIsPriceEnabled(!isPriceEnabled)}
          />
        </Form.Group>

        {/* Price Fields - Hidden if checkbox is not checked */}
        {isPriceEnabled && (
          <Row>


            <Col md={1}>
              <Form.Group>
                <Form.Label className="text-nowrap">Min List Price </Form.Label>
                <div >
                  <Form.Control
                    type='number'
                    placeholder="Min"
                    value={listPriceMin}
                    onChange={(e) => setListPriceMin(e.target.value)}
                    onBlur={() => handleBlurChange('listPriceMin')}
                    disabled={!isPriceEnabled} // Disable field when checkbox is unchecked
                  />

                </div>
              </Form.Group>
            </Col>
            <Col md={1}>
              <Form.Group>
                <Form.Label className="text-nowrap">Max List Price </Form.Label>
                <div className="d-flex gap-2">

                  <Form.Control
                    type='number'
                    placeholder="Max"
                    value={listPriceMax}
                    onChange={(e) => setListPriceMax(e.target.value)}
                    onBlur={() => handleBlurChange('listPriceMax')}
                    disabled={!isPriceEnabled} // Disable field when checkbox is unchecked
                  />
                </div>
              </Form.Group>
            </Col>

          </Row>
        )}

        {/* Submit Button */}
        <div className="mt-3 text-center">
          <Button type="submit" variant="primary" className="w-20">
            Search
          </Button>
        </div>
      </Form>

      {isLoading ? (
        <Loader />
      ) :
      /*error1 ? (
        <Message variant='danger'>{error?.data?.message}</Message>
      ) :*/ ((data && data.products && data.products.length > 0) ?

          <><Row className='align-items-center'>
            <Col>
              <h2>Search results in total of {data.count} records</h2>
            </Col>
          </Row>
            <Table striped bordered hover responsive className='table-sm'>
              <thead>
                <tr>
                  {headers.map((header, index) => (
                    <th key={index} style={{ cursor: 'pointer' }}> {header.label}

                    </th>
                  ))}
                </tr>

              </thead>
              <tbody>
                {data && data.products && data.products.map((product, index) => (
                  <tr key={product._id}>
                    <td> {product.partNumber}</td>
                    <td>{product.uniqueNumber}</td>
                    <td>{product.listPrice}</td>
                    <td>{product.listPrice}</td>
                    <td>{product.row}-{product.bay}-{product.bin}</td>
                    <td>{product.length}-{product.width}-{product.height}</td>
                    <td>{product.isFreeShippingOn === true ? 'Free Shipping' : product.isPaidShippingOn === true ? 'Paid Shipping' : product.isFreightOn === true ? product.freightOption : 'N/A'}</td>
                    <td nowrap='true' style={{ backgroundColor: product.status === 'InProgress' ? 'orange' : 'inherit' }}>{product.status === 'UnlistedWProblems' ? 'Unlisted W/ Problems' : product.status === 'ListedWProblems' ? 'Listed W/ Problems' : product.status}</td>
                    <td>{product.description}</td>
                  </tr>
                ))}
              </tbody>

            </Table>
            <div className="pagination-container mt-3">
              {/* Page numbers navigation */}
              <nav aria-label="Product pagination">
                <ul className="pagination justify-content-center">
                  {/* Previous button */}
                  <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                    <button
                      className="page-link"
                      onClick={() => handlePageChange(Math.max(1, currentPage - 1))}
                      disabled={currentPage === 1}
                    >
                      Previous
                    </button>
                  </li>

                  {/* First page */}
                  <li className={`page-item ${currentPage === 1 ? 'active' : ''}`}>
                    <button className="page-link" onClick={() => handlePageChange(1)}>
                      1
                    </button>
                  </li>

                  {/* Generate visible pages */}
                  {Array.from({ length: Math.ceil(data.count / itemsPerPage) }, (_, i) => i + 1)
                    .filter(page => {
                      return (
                        page === 1 || 
                        page === Math.ceil(data.count / itemsPerPage) || 
                        (page >= currentPage - 2 && page <= currentPage + 2)
                      );
                    })
                    .map((page, index, array) => {
                      if (page === 1 || page === Math.ceil(data.count / itemsPerPage)) {
                        return null;
                      }
                      
                      return (
                        <React.Fragment key={page}>
                          {index > 0 && array[index - 1] !== page - 1 && (
                            <li className="page-item disabled">
                              <span className="page-link">...</span>
                            </li>
                          )}
                          <li className={`page-item ${currentPage === page ? 'active' : ''}`}>
                            <button className="page-link" onClick={() => handlePageChange(page)}>
                              {page}
                            </button>
                          </li>
                        </React.Fragment>
                      );
                    })}

                  {/* Last page */}
                  {Math.ceil(data.count / itemsPerPage) > 1 && (
                    <>
                      {currentPage < Math.ceil(data.count / itemsPerPage) - 3 && (
                        <li className="page-item disabled">
                          <span className="page-link">...</span>
                        </li>
                      )}
                      <li className={`page-item ${currentPage === Math.ceil(data.count / itemsPerPage) ? 'active' : ''}`}>
                        <button className="page-link" onClick={() => handlePageChange(Math.ceil(data.count / itemsPerPage))}>
                          {Math.ceil(data.count / itemsPerPage)}
                        </button>
                      </li>
                    </>
                  )}

                  {/* Next button */}
                  <li className={`page-item ${currentPage === Math.ceil(data.count / itemsPerPage) ? 'disabled' : ''}`}>
                    <button
                      className="page-link"
                      onClick={() => handlePageChange(Math.min(Math.ceil(data.count / itemsPerPage), currentPage + 1))}
                      disabled={currentPage === Math.ceil(data.count / itemsPerPage)}
                    >
                      Next
                    </button>
                  </li>
                </ul>
              </nav>
              
              {/* Jump to page section - PLACED BELOW */}
              <div className="jump-section d-flex justify-content-center mt-2">
                <div className="d-flex align-items-center">
                  <span className="me-2">Go to page:</span>
                  <input
                    type="number"
                    className="form-control form-control-sm jump-input"
                    style={{ width: "60px" }}
                    min={1}
                    max={Math.ceil(data.count / itemsPerPage)}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        const page = Math.min(
                          Math.max(1, parseInt(e.target.value) || 1),
                          Math.ceil(data.count / itemsPerPage)
                        );
                        handlePageChange(page);
                        e.target.value = '';
                      }
                    }}
                  />
                  <button 
                    className="btn btn-sm btn-outline-primary ms-1"
                    onClick={(e) => {
                      const input = e.target.previousSibling;
                      const page = Math.min(
                        Math.max(1, parseInt(input.value) || 1),
                        Math.ceil(data.count / itemsPerPage)
                      );
                      handlePageChange(page);
                      input.value = '';
                    }}
                  >
                    Go
                  </button>
                </div>
              </div>
              
              {/* Items per page dropdown could also be moved here */}
              <div className="d-flex justify-content-between align-items-center mt-2">
                <div>
                  <select
                    className="form-select form-select-sm"
                    value={itemsPerPage}
                    onChange={(e) => {
                      const newLimit = Number(e.target.value);
                      setItemsPerPage(newLimit);
                      setCurrentPage(1);
                      fetchReportData(1, newLimit);
                    }}
                  >
                    <option value={100}>100 per page</option>
                    <option value={250}>250 per page</option>
                    <option value={500}>500 per page</option>
                    <option value={1000}>1000 per page</option>
                  </select>
                </div>
                <div>
                  Page {currentPage} of {Math.ceil(data.count / itemsPerPage) || 1}
                </div>
              </div>
            </div>

            <ExportToCSV
              headers={reportHeaders}
              queryParams={{
                condition,
                status,
                isPriceEnabled,
                isShippingEnabled,
                isFreeShippingOn,
                isPaidShippingOn,
                freightOption,
                listPriceMin,
                listPriceMax,
                exportAll: true
              }}
              isActive={true}
            />

          </>
          :

          <Row className='align-items-center'>
            <Col>
              <h5>No records were found...</h5>
            </Col>
          </Row>
        )
      }
    </>
  )

}
export default CCCReportSearch;