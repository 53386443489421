import { useState, useEffect, useCallback } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Form, Button } from 'react-bootstrap';
import Message from '../components/Message';
import Loader from '../components/Loader';
import FormContainer from '../components/FormContainer';
import { toast } from 'react-toastify';
import { MdBarcodeReader } from "react-icons/md";
import { Select, Space } from 'antd';
import {
  useGetProductDetailsQuery,
  useUpdateProductMutation,

  useDisplayProductImageMutation
} from '../slices/productsApiSlice';

import BarcodeScanner from '../components/BarcodeScanner';
import splitNumbers from '../components/SplitNumbers';
import { conditionReasonsList, conditionsList, statusOptions } from '../constants';
import FreightOptions from '../components/FreightOptions';
import DimensionInputs from '../components/DimensionsInput';
import ImageUpload from '../components/ImageUpload';
import PublicImageUpload from '../components/PublicImageUpload';
import numeral from 'numeral';

const ProductEditScreen = () => {

  const { id: productId, from } = useParams();

  const [partNumber, setPartNumber] = useState('');
  const [comment, setComment] = useState('');
  const [images, setImages] = useState([]);
  const [row, setRow] = useState('');
  const [status, setStatus] = useState('0');//unlisted
  const [condition, setCondition] = useState('');
  const [bay, setBay] = useState(null);
  const [bin, setBin] = useState(null);
  const [section, setSection] = useState(null);
  const [conditionReason, setConditionReason] = useState([]);

  const [weight, setWeight] = useState(null);
  const [length, setLength] = useState(null);
  const [height, setHeight] = useState(null);
  const [width, setWidth] = useState(null);
  const [uniqueNumber, setUniqueNumber] = useState('');
  const [imageUrls, setImageUrls] = useState([]);
  const [isCameraVisible, setCameraVisible] = useState(false);
  const [freightOption, setFreightOption] = useState('');
  const [isFreightOn, setIsFreightOn] = useState(false);
  const [isFreeShippingOn, setIsFreeShippingOn] = useState(false);
  const [isPaidShippingOn, setIsPaidShippingOn] = useState(false);
  const [quantity, setQuantity] = useState('');
  const [publicImages, setPublicImages] = useState([]);
  const [navigationPath, setNavigationPath] = useState('');
  const [netPrice, setNetPrice] = useState(null);
  const [listPrice, setListPrice] = useState(null);
  const [description, setDecription] = useState(null);

  const {
    data: product,
    isLoading,
    refetch,
    error,
  } = useGetProductDetailsQuery(productId);

  const [updateProduct, { isLoading: loadingUpdate }] =
    useUpdateProductMutation();


  const [selectedImages, setSelectedImages] = useState([]);
  const { userInfo } = useSelector((state) => state.auth);


  const [displayProductImages] = useDisplayProductImageMutation();


  const displayFileHandler = useCallback(async (e) => {

    try {

      const res = await displayProductImages(e)
      // .then(({data}) => {
      //   console.log(data)
      //   setImages(data.presignedUrls)
      // });

      setImageUrls(res.data.presignedUrls);
      return res.presignedUrls

    } catch (err) {
      toast.error(err?.data?.message || err.error);
    }
  }, [displayProductImages]);



  const handleFreightChange = (switchType) => {
    setIsFreightOn(false);
    setIsFreeShippingOn(false);
    setIsPaidShippingOn(false);

    switch (switchType) {
      case 'freight':
        setIsFreightOn(true);
        break;
      case 'freeShipping':
        setIsFreeShippingOn(true);
        setFreightOption("");
        break;
      case 'paidShipping':
        setIsPaidShippingOn(true);
        setFreightOption("");
        break;
      default:
        break;
    }


  };
  const handleBarcodeScan = (scannedText) => {
    const splittedNumbers = splitNumbers(scannedText);
    if (splittedNumbers.length === 3) {
      setRow(splittedNumbers[0]);
      setBay(splittedNumbers[1]);
      setBin(splittedNumbers[2]);

    } else if (splittedNumbers.length === 4) {
      setRow(splittedNumbers[0]);
      setBay(splittedNumbers[1]);
      setBin(splittedNumbers[2]);
      setSection(splittedNumbers[3]);

    }

    else {
      toast.error('Error in parsing row/bay/bin');
    }
    setCameraVisible(false);
  };
  const handleBlurChange = (priceType) => {
   //alert(priceType);
    if(priceType==='netPrice'){
      setNetPrice(numeral(netPrice).format('0.00'));
    }
    else{
      setListPrice(numeral(listPrice).format('0.00'));
    }
   
  };


  const displayCamera = () => {
    // Toggle the visibility of the code
    setCameraVisible(!isCameraVisible);
  };
  const navigate = useNavigate();

  useEffect(() => {
    if (product) {

      // console.log('product: '+ product);
      setCondition(product.condition);
      setPartNumber(product.partNumber);
      setRow(product.row);
      setBay(product.bay);
      setBin(product.bin);
      setSection(product.section);
      setWeight(product.weight);
      setLength(product.length);
      setHeight(product.height);
      setWidth(product.width);
      setUniqueNumber(product.uniqueNumber);
      setStatus(product.status);
      displayFileHandler(product.images);
      setImages(product.images);
      setComment(product.comment);
      setFreightOption(product.freightOption);
      setIsFreightOn(product.isFreightOn);
      setIsFreeShippingOn(product.isFreeShippingOn);
      setIsPaidShippingOn(product.isPaidShippingOn);
      setConditionReason(product.conditionReason);
      setQuantity(product.quantity);
      setPublicImages(product.publicImages);
      setNetPrice(product.netPrice);
      setListPrice(product.listPrice);
      setDecription(product.description);
    }
  }, [product, displayFileHandler]);

  useEffect(() => {
    let navPath = '/productlist'
    if (from === 'idleProduct')
      navPath = '/idleProducts'
    else if (from === 'ListedProducts')
      navPath = '/getStatus'
    else if (from === 'SoldProducts')
      navPath = '/getSoldStatus'
    else if (from === 'CheapItemProducts')
      navPath = '/cheapItemProducts'
    else if (from === 'bulkUpload')
      navPath = '/bulkUploadReport'

    setNavigationPath(navPath);

  }, [from]);



  const submitHandler = async (e) => {
    e.preventDefault();
    // Validation checks
    let shippingOptionError = '';
    let conditionReasonError = '';
    if (!isFreeShippingOn && !isPaidShippingOn && !isFreightOn) {
      shippingOptionError = 'Please select at least one shipping option!';
    }

    if ((condition === 'NewOther' || condition === 'Used') && conditionReason && conditionReason.length === 0) {
      conditionReasonError = 'Please select at least one condition reason!';
    }
   

    // Display error messages
    if (shippingOptionError || conditionReasonError) {
      const combinedErrorMessage = `${shippingOptionError}\n${conditionReasonError}`;
      toast.error(combinedErrorMessage);
      return;
    }
    const productData = {
      productId,
      partNumber,
      condition,
      weight,
      status,
      row,
      bay,
      bin,
      length,
      height,
      width,
      uniqueNumber,
      images,
      comment,
      freightOption,
      isFreightOn,
      isFreeShippingOn,
      isPaidShippingOn,
      section,
      conditionReason,
      quantity,
      publicImages,
      netPrice,
      listPrice,
      description
    };
    try {
      await updateProduct(productData).unwrap(); // NOTE: here we need to unwrap the Promise to catch any rejection in our catch block
      toast.success('Product updated');
      refetch();

      navigate(navigationPath);

    } catch (err) {
      toast.error(err?.data?.message || err.error);
    }

  };

  return (
    <>
      <Link to={navigationPath} className='btn btn-light my-3'> Go Back </Link>
      <FormContainer>
        <h1>Edit Product</h1>
        {loadingUpdate && <Loader />}
        {isLoading ? (
          <Loader />
        ) : error ? (
          <Message variant='danger'>{error.data.message}</Message>
        ) :
          (
            <Form onSubmit={submitHandler}>

              <ImageUpload
                images={images}
                setImages={setImages}
                imageUrls={imageUrls}
                selectedImages={selectedImages}
                setImageUrls={setImageUrls}
                userInfo={userInfo}
              />
              <PublicImageUpload
                publicImages={publicImages}
                setPublicImages={setPublicImages}
                selectedImages={selectedImages}
                userInfo={userInfo}
              />
              <Form.Group controlId='uniqueNumber'>
                <Form.Label>Unique Number</Form.Label>
                <Form.Control
                  type='number'
                  placeholder='Enter unique number'
                  value={uniqueNumber}
                  onChange={(e) => setUniqueNumber(e.target.value)}
                  required
                  readOnly={!userInfo?.role?.permissions?.includes("can_edit_product_fields")}
                ></Form.Control>
              </Form.Group>

              <Form.Group controlId='c'>
                <Form.Label>Part Number</Form.Label>
                <Form.Control
                  type='text'
                  placeholder='Enter part number'
                  value={partNumber}
                  onChange={(e) => setPartNumber(e.target.value)}
                  required
                ></Form.Control>
              </Form.Group>
              <Form.Group controlId='quantity'>
                <div className="row" >
                  <div className="col-md-2">
                    <Form.Label>Quantity</Form.Label>
                    <div className="input-group">
                      <Form.Control
                        type='number'
                        value={quantity}
                        onChange={(e) => setQuantity(e.target.value)}

                        readOnly={!userInfo?.role?.permissions?.includes("can_edit_product_fields")}
                      />

                    </div>
                  </div>
                </div>
              </Form.Group>

              <Form.Group controlId='condition'>
                <Form.Label>Condition</Form.Label>
                <Form.Control
                  as='select'
                  placeholder='Enter condition'
                  value={condition}
                  disabled={!userInfo?.role?.permissions?.includes("can_edit_product_fields")}
                  onChange={(e) => setCondition(e.target.value)}
                  required
                >
                  {conditionsList.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
              {(condition === 'NewOther' || condition === 'Used') && (<Form.Group controlId='conditionReason' >
                <Form.Label>Condition Reason </Form.Label>
                <Select
                  mode="multiple"
                  style={{
                    width: '100%',
                    height: '50px',
                  }}

                  placeholder="Select Reason"
                  value={conditionReason}
                  disabled={!userInfo?.role?.permissions?.includes("can_edit_product_fields")}
                  onChange={(selectedValues) => setConditionReason(selectedValues)}
                  optionLabelProp="label"
                  options={conditionReasonsList}
                  optionRender={(option) => (
                    <Space>
                      <span role="img" aria-label={option.data.label}>
                        {option.data.label}
                      </span>

                    </Space>
                  )}

                />
              </Form.Group>)}
              <Form.Group controlId='weight'>
                <div className="row" >
                  <div className="col-md-3">
                    <Form.Label>Weight</Form.Label>
                    <div className="input-group">
                      <Form.Control
                        type='number'
                        value={weight}
                        onChange={(e) => setWeight(e.target.value)}
                        required
                        readOnly={!userInfo?.role?.permissions?.includes("can_edit_product_fields")}
                      />
                      <div className="input-group-append">
                        <span className="input-group-text" >LBS</span>
                      </div>
                    </div>
                  </div>
                </div>
              </Form.Group>
              <DimensionInputs
                length={length}
                width={width}
                height={height}
                onLengthChange={(value) => setLength(value)}
                onWidthChange={(value) => setWidth(value)}
                onHeightChange={(value) => setHeight(value)}
                canEditField={userInfo?.role?.permissions?.includes("can_edit_product_fields")}
              />
              <Form.Group >
                <div className="row">

                  <div className="col">

                    <Form.Check
                      type="switch"
                      id="freight"
                      label="Freight"
                      onChange={() => handleFreightChange('freight')}
                      checked={isFreightOn}
                      disabled={!userInfo?.role?.permissions?.includes("can_edit_product_fields")}
                    />
                  </div>
                  <div className="col">

                    <Form.Check
                      type="switch"
                      id="freeShipping"
                      label="Free Shipping"
                      onChange={() => handleFreightChange('freeShipping')}
                      checked={isFreeShippingOn}
                      disabled={!userInfo?.role?.permissions?.includes("can_edit_product_fields")}
                    />
                  </div>
                  <div className="col">

                    <Form.Check
                      type="switch"
                      id="paidShipping"
                      label="Paid Shipping"
                      onChange={() => handleFreightChange('paidShipping')}
                      checked={isPaidShippingOn}
                      disabled={!userInfo?.role?.permissions?.includes("can_edit_product_fields")}
                    />
                  </div>
                  {isFreightOn && <FreightOptions

                    freightOption={freightOption}
                    onFreightOptionChange={(value) => setFreightOption(value)}
                    canEditField={userInfo?.role?.permissions?.includes("can_edit_product_fields")}
                  />}

                </div>
              </Form.Group>



              <Form.Group controlId='name'>
                <div><br></br>
                  <Button onClick={displayCamera} className={`p-2 mx-2 ${isCameraVisible ? 'active' : ''}`}>
                    <MdBarcodeReader />
                  </Button>
                </div>
                {isCameraVisible ?

                  <BarcodeScanner onBarcodeScan={handleBarcodeScan} /> : ''}

              </Form.Group>

              <Form.Group >
                <div className="row">

                  <div className="col">
                    <Form.Label>Row</Form.Label>
                    <Form.Control
                      type='number'
                      value={row}
                      onChange={(e) => setRow(e.target.value)}
                      required
                      readOnly={!userInfo?.role?.permissions?.includes("can_edit_product_fields")}
                    >
                    </Form.Control>
                  </div>

                  <div className="col">
                    <Form.Label>Bay</Form.Label>
                    <Form.Control
                      type='number'
                      required
                      value={bay}
                      onChange={(e) => setBay(e.target.value)}
                      readOnly={!userInfo?.role?.permissions?.includes("can_edit_product_fields")}
                    ></Form.Control>
                  </div>
                  <div className="col">
                    <Form.Label>Bin</Form.Label>
                    <Form.Control
                      type='number'
                      required
                      value={bin}
                      onChange={(e) => setBin(e.target.value)}
                      readOnly={!userInfo?.role?.permissions?.includes("can_edit_product_fields")}
                    ></Form.Control>
                  </div>

                  <div className="col">
                    <Form.Label>Section</Form.Label>
                    <Form.Control
                      type='number'
                      value={section}
                      onChange={(e) => setSection(e.target.value)}
                      readOnly={!userInfo?.role?.permissions?.includes("can_edit_product_fields")}
                    ></Form.Control>
                  </div>

                </div>
              </Form.Group>

              <Form.Group controlId='lister'>
                <Form.Label >Lister</Form.Label>
                <Form.Control
                  placeholder='N/A'
                  value={(product.lister ? product.lister.name : 'N/A')}
                  readOnly
                ></Form.Control>

              </Form.Group>
            

              <Form.Group >
                <div className="row">


                  <div className="col-md-3">
                    <Form.Label>Net Price</Form.Label>
                    <div className="input-group">
                      <Form.Control
                        type='number'

                        value={netPrice}
                        onChange={(e) => setNetPrice(e.target.value)}
                        onBlur={() => handleBlurChange('netPrice')}   // Format on blur
                        readOnly={!userInfo?.role?.permissions?.includes("can_edit_product_fields")}
                      >


                      </Form.Control>
                      <div className="input-group-append">
                        <span className="input-group-text" >$</span>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <Form.Label>List Price</Form.Label>
                    <div className="input-group">
                      <Form.Control
                        type='number'

                        value={listPrice}
                        onChange={(e) => setListPrice(e.target.value)}
                        readOnly={!userInfo?.role?.permissions?.includes("can_edit_product_price")}
                        onBlur={() => handleBlurChange('listPrice')}   // Format on blur
                      ></Form.Control>
                      <div className="input-group-append">
                        <span className="input-group-text" >$</span>
                      </div>

                    </div>
                  </div>
                </div>
              </Form.Group>
              <Form.Group controlId='description'>
                <Form.Label>Description</Form.Label>
                <Form.Control
                  as="textarea" rows={2}
                  placeholder='Enter description'
                  value={description}
                  onChange={(e) => setDecription(e.target.value)}
                  readOnly={!userInfo?.role?.permissions?.includes("can_edit_description")}
                ></Form.Control>

              </Form.Group>
              <Form.Group controlId='status'>
                <Form.Label>Status</Form.Label>
                <Form.Control
                  as='select'
                  placeholder='Enter status'
                  value={status}
                  onChange={(e) => setStatus(e.target.value)}
                >
                  {
                    statusOptions.map((option) => (

                      ((userInfo?.role?.permissions?.includes('can_view_' + option + '_status') || status === option) && <option key={option} value={option}>
                        {option}
                      </option>
                      )))}

                </Form.Control>
              </Form.Group>
              <Form.Group controlId='comment'>
                <Form.Label>Comment</Form.Label>
                <Form.Control
                  as="textarea" rows={2}
                  placeholder='Enter comment (optional)'
                  value={comment}
                  onChange={(e) => setComment(e.target.value)}
                  readOnly={(userInfo?.role?.permissions?.includes("can_edit_comment") ? false : !(status === 'UnlistedWProblems'))}// in case no permission but status UnlistedWProblems comment should be editable
                ></Form.Control>

              </Form.Group>
              <Button
                type='submit'
                variant='primary'
                style={{ marginTop: '1rem' }}
              >
                Update
              </Button>
            </Form>
          )}
      </FormContainer>
    </>
  );
}

export default ProductEditScreen;