//import {Navbar, Nav, Container} from 'react-bootstrap';

import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import logo from '../assets/logo-big.png';
import { useLogoutMutation } from '../slices/usersApiSlice';
import { logout } from '../slices/authSlice';
import { LinkContainer } from 'react-router-bootstrap';




const Header = () => {

  const { userInfo } = useSelector((state) => state.auth);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [logoutApiCall] = useLogoutMutation();

  const logoutHandler = async () => {
    try {
      await logoutApiCall().unwrap();
      dispatch(logout());

      navigate('/login');
    } catch (err) {
      console.log(err);
    }
  }
  return (
    <header>
      <Navbar bg='primary' variant='dark' expand='lg' collapseOnSelect>
        <Container>
          <LinkContainer to="/">
            <Navbar.Brand>
              <img src={logo} alt='Fastlane' />

            </Navbar.Brand>

          </LinkContainer>

          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ms-auto">

              <>
                {userInfo && (
                  <NavDropdown title='Products' id='adminmenu'>
                    {userInfo.role && userInfo.role.permissions && userInfo.role.permissions.includes("can_access_products") && (

                      <LinkContainer to='/productlist'>
                        <NavDropdown.Item>Products List </NavDropdown.Item>
                      </LinkContainer>)}
                    {(userInfo.role && userInfo.role.permissions && userInfo.role.permissions.includes("can_access_listed_items")) && (<LinkContainer to='/getStatus'>
                      <NavDropdown.Item>Listed Products</NavDropdown.Item>
                    </LinkContainer>)}
                    {(userInfo.role && userInfo.role.permissions && userInfo.role.permissions.includes("can_access_sold_items")) && (<LinkContainer to='/getSoldStatus'>
                      <NavDropdown.Item>Sold Products</NavDropdown.Item>


                    </LinkContainer>)}

                  </NavDropdown>
                )}
                {userInfo && (userInfo.role && userInfo.role.permissions && userInfo.role.permissions.includes("can_access_idle_items")) && (
                  <NavDropdown title='Idle Products' id='adminmenu'>
                    <LinkContainer to='/idleProducts'>
                      <NavDropdown.Item>Idle Products List</NavDropdown.Item>
                    </LinkContainer>

                  </NavDropdown>
                )}
                {userInfo && (userInfo.role && userInfo.role.permissions && userInfo.role.permissions.includes("can_access_cheap_items")) && (
                  <NavDropdown title='Cheap Items' id='adminmenu'>
                    <LinkContainer to='/cheapItemProducts'>
                      <NavDropdown.Item>Cheap Items List</NavDropdown.Item>
                    </LinkContainer>

                  </NavDropdown>
                )}
                {userInfo && (userInfo.role && userInfo.role.permissions && userInfo.role.permissions.includes("can_access_reports")) && (
                  <NavDropdown title='Reports' id='adminmenu'>
                    <LinkContainer to='/report'>
                      <NavDropdown.Item>Product Report</NavDropdown.Item>
                    </LinkContainer>
                    <LinkContainer to='/cccReport'>
                      <NavDropdown.Item>CCC Report</NavDropdown.Item>
                    </LinkContainer>

                  </NavDropdown>
                )}
                {userInfo && (userInfo.role && userInfo.role.permissions && userInfo.role.permissions.includes("can_access_tools")) && (
                  <NavDropdown title='Tools' id='adminmenu'>

                    <LinkContainer to='/bulkUploadReport'>
                      <NavDropdown.Item>Parts Picker</NavDropdown.Item>
                    </LinkContainer>
                  </NavDropdown>
                )}
                {userInfo && (<NavDropdown title={userInfo.name} id='adminmenu'>

                  {userInfo && (userInfo.role && userInfo.role.permissions && userInfo.role.permissions.includes("can_access_users")) && (
                    <LinkContainer to='/admin/userlist'>
                      <NavDropdown.Item>Users</NavDropdown.Item>
                    </LinkContainer>
                  )}
                  {userInfo && userInfo.role && userInfo.role.permissions && userInfo.role.permissions.includes("can_access_roles") && (<LinkContainer to='/admin/rolelist'>
                    <NavDropdown.Item>Roles</NavDropdown.Item>
                  </LinkContainer>
                  )}
                  <LinkContainer to='/profile'>
                    <NavDropdown.Item>My Profile</NavDropdown.Item>
                  </LinkContainer>
                  <NavDropdown.Item onClick={logoutHandler}>
                    Logout
                  </NavDropdown.Item>

                </NavDropdown>
                )}
              </>

            </Nav>

          </Navbar.Collapse>


        </Container>

      </Navbar>

    </header>
  )
}
export default Header