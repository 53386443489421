// FreightOptions.js
import React from 'react';
import { Form } from 'react-bootstrap';
import { freightOptions } from '../constants';

const FreightOptions = ({ freightOption, onFreightOptionChange, canEditField, multiple=false }) => {
  return (
    <Form.Group controlId='freight'>
      <Form.Label>Freight</Form.Label>
      <Form.Select
        value={freightOption}
        onChange={(e) => onFreightOptionChange(Array.from(e.target.selectedOptions, option => option.value))}
        disabled={!canEditField}
        multiple = {multiple}
      >
        {freightOptions.map((option) => (
          <option key={option} value={option}>
            {option}
          </option>
        ))}
      </Form.Select>
    </Form.Group>
  );
};

export default FreightOptions;
