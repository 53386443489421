import React, { useState } from 'react';
import { FloatButton } from 'antd';
import { ExportOutlined,LoadingOutlined } from '@ant-design/icons';
import { saveAs } from 'file-saver';
import { toast } from 'react-toastify';
import axiosInstance from '../utils/axiosInstance';

const ExportToCSV = ({ headers, queryParams, isActive }) => {
  const [exporting, setExporting] = useState(false);
  const [isDataAvailable, setIsDataAvailable] = useState(false);

  const handleExportCSV = async () => {
    if (!isActive) return;
    setExporting(true);
    setIsDataAvailable(true);
    try {
      const { data } = await axiosInstance.get('/api/reports/cccReport', {
        params: queryParams
      });

      const csvContent = `\uFEFF${createCSVContent(headers, data.allProducts)}`;
      const filename = 'report-sheet.csv';
      const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8' });
      saveAs(blob, filename);

    } catch (error) {
      console.error('Export error:', error);
      toast.error('Export failed');

      
    } finally {
      setExporting(false);
    }


  };
// Sanitize CSV value to remove problematic characters
const sanitizeCSVValue = (value) => {
  if (value === null || value === undefined) return '';
  
  const stringValue = String(value);
  
  // Option 1: Replace commas and newlines with spaces
  return stringValue.replace(/,|\n|\r/g, ' ');
  
  
};
  const createCSVContent = (headers, data) => {
    const headerRow = headers.map((header) => header.label || header.key).join(',');

    // Modify the dataRows creation to handle arrays
    const dataRows = data.map((row) => {
      return headers.map((header) => {
        // Check if the current header corresponds to an array field
        if (header.key === 'partType') {
          return 'OD';
        }
        else if (header.key === 'available') {
          return 'A';
        }
        else if (header.key === 'yardLocation') {
          return   `'${String(row['row'] ?? '')}-${String(row['bay'] ?? '')}-${String(row['bin'] ?? '')}`;
        }
        
       else if (Array.isArray(row[header.key])) {
          // If it's an array, join its values with commas
          return sanitizeCSVValue(row[header.key]).join('|');
        } else {
          // If it's not an array, use the value directly
          return sanitizeCSVValue(row[header.key]);
        }
      }).join(',');
    }).join('\n');

    return `${headerRow}\n${dataRows}`;

  }


  return (
    <FloatButton
    tooltip={exporting ? 'Exporting...' : 'Export to CSV'}
    type='primary'
    onClick={handleExportCSV}
    icon={exporting ? <LoadingOutlined /> : <ExportOutlined />}
    className={`${isDataAvailable ? 'collapse' : ''} export-button`}
    disabled={!isActive || exporting}
  />
)};

export default ExportToCSV;
